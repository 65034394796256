<template>
  <tabs-topbar
    :tabs="tabs"
    :modules="[]"
    data-id-prefix="profile_topbar_tabs"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '../profile.module'

export default {
  name: 'ProfileTopbarTabs',
  components: {
    TabsTopbar,
  },
  computed: {
    tabs() {
      return [
        {
          label: () => 'My Profile',
          route: { name: `${PROFILE_MODULE_NAME}.details` },
        },
      ]
    },
  },
}
</script>
