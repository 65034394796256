import { Module } from 'vuex'
import { RouteConfig } from 'vue-router'
import store, { RootState } from '@/store'
import router from '@/router'
import { resetState } from '@/utils/store'
import { ProfileState } from './store/profile.state'

const state = {
  isStoreInstalled: false,
  isRoutesInstalled: false,
}

// module name must match feature hint from backend API response
export const MODULE_NAME = 'Profile'

export const STATE_RESET_MUTATION_NAME = `resetState`

interface InstallOptions {
  store: boolean
  routes: boolean
}
const defaultInstallOptions: InstallOptions = {
  store: true,
  routes: true,
}
export function install(
  options: InstallOptions = defaultInstallOptions
): Promise<any> {
  const promises = []

  if (options.store) {
    promises.push(
      import(/* webpackChunkName: 'ProfileModule' */ './store/profile.store')
        .then(exports.registerStore)
        .then(() => (state.isStoreInstalled = true))
    )
  }

  if (options.routes) {
    promises.push(
      import(/* webpackChunkName: 'ProfileModule' */ './routes/profile.routes')
        .then(exports.addRoutes)
        .then(() => (state.isRoutesInstalled = true))
    )
  }

  return Promise.all(promises)
}

export function uninstall() {
  if (state.isStoreInstalled) {
    store.commit(`${MODULE_NAME}/${STATE_RESET_MUTATION_NAME}`)
    store.unregisterModule(MODULE_NAME)
    state.isStoreInstalled = false
  }
}

export function registerStore(localStore: {
  default: Module<ProfileState, RootState>
  makeEmptyState(): ProfileState
}) {
  // dynamically register state reset mutation for use in uninstall()
  if (!localStore.default.mutations) {
    localStore.default.mutations = {}
  }
  localStore.default.mutations[STATE_RESET_MUTATION_NAME] = function (
    state: ProfileState
  ) {
    resetState(state, localStore.makeEmptyState())
  }
  store.registerModule(MODULE_NAME, localStore.default)
}

export function addRoutes(localRoutes: { default: Array<RouteConfig> }) {
  for (const route of localRoutes.default) {
    router.addRoute(route)
  }
}
