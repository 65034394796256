import { MODULE_NAME } from '../profile.module'
import ProfileTopbarTabs from '../components/ProfileTopbarTabs.vue'
import { checkUserRecordPermission } from '../../employee/routes/employee.interceptors'

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout.vue'
  )

export default [
  {
    path: '/workspaces/:workspaceId/profile',
    name: `${MODULE_NAME}.index`,
    meta: {
      feature: MODULE_NAME,
      layout: DefaultLayout,
      hiddenElements: ['areas', 'tags'],
    },
    components: {
      'topbar-left': ProfileTopbarTabs,
      default: () =>
        import('@/app/modules/employee/pages/EmployeeDetailsPage.vue'),
    },
    children: [
      {
        path: '',
        name: `${MODULE_NAME}.details`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () =>
          import('@/app/modules/employee/pages/EmployeeDetailsGeneralPage.vue'),
      },
      {
        path: 'target-hours',
        name: `${MODULE_NAME}.details.targetHours`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () =>
          import(
            '@/app/modules/employee/pages/EmployeeDetailsTargetHoursPage.vue'
          ),
      },
      {
        path: 'records',
        name: `${MODULE_NAME}.details.records`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () =>
          import('@/app/modules/employee/pages/EmployeeDetailsRecordsPage.vue'),
        beforeEnter: checkUserRecordPermission(MODULE_NAME),
      },
      {
        path: 'vacations',
        name: `${MODULE_NAME}.details.vacations`,
        meta: {
          feature: MODULE_NAME,
          layout: DefaultLayout,
          hiddenElements: ['areas', 'tags'],
        },
        component: () =>
          import(
            '@/app/modules/employee/pages/EmployeeDetailsVacationsPage.vue'
          ),
      },
    ],
  },
]
