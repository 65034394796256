import type {
  State,
  RecordsCollection,
  RecordsIndexes,
} from '@/utils/jsonapi/types'

export interface ProfileState extends State {
  records: RecordsCollection
  // used for data indexing by jsonapi utils.
  recordsIndexes: RecordsIndexes
}

/* istanbul ignore next */
export const makeEmptyState = (): ProfileState => ({
  records: {},
  recordsIndexes: {},
})

export default makeEmptyState()
